<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import store from "@/state/store";
import SalesReportWidgets from "@/views/pages/dashboard/components/SalesReportWidgets.vue";

export default {
  components: {
    Multiselect,
    SalesReportWidgets
  },
  data() {
    return {

      multiselect: {
        searchable: true,
        placeholder: 'Search by usernames...',
        options: [],
        mode: 'tags'
      },

      form: {
        manager_ids: [],
        date_from: '',
        date_to: '',
        date_range_option: 'today',
        platform: 'ag',
        filter_by_dates: []
      },

      date_range_options: [],
      date_range_months: [],

      reports: [],
      reports_request_status: '',
      showModal: false,

      search: {
        platform: '',
        manager: '',
        customer: '',
        route: '',
        cargo: '',
        order_number: '',
        position: '',
        date: '',
        volume: '',
        sales_sum: '',
        margin: '',
      },

      current_year: new Date().getFullYear(),

      object_keys: {
        rail_forwarder: 'Rail forwarder',
        block_train: 'Block train',
        multi_modal: 'Multi modal',

        container_order: '/orders/container/view',
        wagon_order: '/orders/wagon/view',
        wagon_empty_order: '/orders/empty-wagon/view'
      },

      show_margin: false
    }
  },
  computed: {
    getUserUsername() {
      return store.state.user.username
    },
    filteredReports() {
      return this.reports.filter(report => {
        const managerName = report.user ? (report.user.first_name || '') + ' ' + (report.user.last_name || '') : '';
        const companyName = report.company ? report.company.name : '';
        const platform = report.platform ? report.platform : '';
        const route = (report.departure ? report.departure.name + ' (' + report.departure.code + ')' : '') + ' - ' + (report.destination ? report.destination.name + ' (' + report.destination.code + ')' : '');
        const cargo = report.product ? report.product.name + ' (' + report.product.hc_code + ')' : '';
        const volume = report.child_type === 'container_order' ? report.container_types.map(type => type.quantity + 'x' + type.type).join(', ') : (report.quantity || 0) + 'xW';
        const salesSum = parseFloat(report.total_agreed_rate || '0').toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const margin = (parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        return (
            platform.toLowerCase().includes(this.search.platform.toLowerCase()) &&
            managerName.toLowerCase().includes(this.search.manager.toLowerCase()) &&
            companyName.toLowerCase().includes(this.search.customer.toLowerCase()) &&
            route.toLowerCase().includes(this.search.route.toLowerCase()) &&
            cargo.toLowerCase().includes(this.search.cargo.toLowerCase()) &&
            report.position.toLowerCase().includes(this.search.position.toLowerCase()) &&
            report.order_number.toLowerCase().includes(this.search.order_number.toLowerCase()) &&
            (report.date || '').toLowerCase().includes(this.search.date.toLowerCase()) &&
            volume.toLowerCase().includes(this.search.volume.toLowerCase()) &&
            salesSum.toLowerCase().includes(this.search.sales_sum.toLowerCase()) &&
            margin.toLowerCase().includes(this.search.margin.toLowerCase())
        );
      });
    },
    total_quantity() {
      return this.filteredReports.map(i => i.quantity).reduce((a, b) => a + b, 0)
    },
    isDateRangeInMonth() {
      if (this.form.date_from && this.form.date_to) {
        const startDate = moment(this.form.date_from);
        const endDate = moment(this.form.date_to);

        if (!startDate.isValid() || !endDate.isValid()) {
          throw new Error("Invalid date input");
        }

        const startOfMonth = startDate.clone().startOf("month");
        const endOfMonth = startDate.clone().endOf("month");

        if (
            startDate.isSame(startOfMonth, "day") &&
            endDate.isSame(endOfMonth, "day")
        ) {
          return {ok: true, month: startDate.format("MMMM")};
        }
      }

      return {ok: false};
    },
  },
  methods: {
    async asyncFindUsers() {
      try {
        let response = await axios.get("/user/", {
          params: {
            company: 'interrail uzbekistan'
          }
        });
        let newOptions = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });

        this.multiselect.options = [...this.multiselect.options, ...newOptions];

        this.multiselect.options = this.multiselect.options.reduce((unique, o) => {
          if (!unique.find(obj => obj.value === o.value && obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
      } catch {
        alert("Could not search for managers")
      }
    },
    async generateReport(close_modal_if_no_orders = true) {

      this.reports_request_status = ''

      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      });

      if (this.form.date_from.toString().trim().length === 0) {
        return await Toast.fire({
          title: 'Select date from',
          icon: 'info'
        })
      }

      if (this.form.date_to.toString().trim().length === 0) {
        return await Toast.fire({
          title: 'Select date to',
          icon: 'info'
        })
      }

      try {
        this.reports_request_status = 'loading'
        let response = await axios.get('/order/sales_report/', {
          params: {
            from_date: this.form.date_from,
            to_date: this.form.date_to,
            manager_ids: this.form.manager_ids,
            platform: this.form.platform,
          }
        })
        this.reports = response.data.map(report => {
          return {
            ...report,
          }
        })

        if (close_modal_if_no_orders) {
          this.showModal = this.reports.length > 0
        } else {
          this.showModal = true
        }

        this.reports_request_status = 'success'
      } catch {
        this.reports = []
        this.reports_request_status = 'error'
      }
    },

    capitalizeFirstLetter(string) {
      if (!string) return '-'
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    setDateRange(option) {
      this.form.date_range_option = option;

      switch (option) {
        case 'today':
          this.form.date_from = new moment().format('YYYY-MM-DD');
          this.form.date_to = new moment().format('YYYY-MM-DD');
          break;
        case 'last_week':
          this.form.date_from = new moment().subtract(6, 'days').format('YYYY-MM-DD');
          this.form.date_to = new moment().format('YYYY-MM-DD');
          break;
        case 'last_month':
          this.form.date_from = new moment().subtract(30, 'days').format('YYYY-MM-DD');
          this.form.date_to = new moment().format('YYYY-MM-DD');
          break;
        case 'current_year':
          this.form.date_from = new moment(`${this.current_year}-01-01`).startOf('year').format('YYYY-MM-DD');
          this.form.date_to = new moment(`${this.current_year}-01-01`).endOf('year').format('YYYY-MM-DD');
          break;
        case 'custom':
          this.form.date_from = ''
          this.form.date_to = ''
          break;
      }
    },

    async setFormDateRanges(month, close_modal_if_no_orders) {
      this.form.date_from = month.date_from
      this.form.date_to = month.date_to
      await this.generateReport(close_modal_if_no_orders)
    },

    totalRevenue() {
      try {
        return this.filteredReports.map(report => {
          return parseFloat(report.total_agreed_rate || '0')
        }).reduce((a, b) => a + b, 0);
      } catch {
        return 0
      }
    },

    formatTotalAgreedRateByPosition(position) {
      return this.filteredReports.filter(report => report.position === position).map(report => {
        return parseFloat(report.total_agreed_rate || '0')
      }).reduce((a, b) => a + b, 0);
    },

    formatTotalExpenseByPosition(position) {
      let value

      if (position) {
        value = this.filteredReports.filter(report => report.position === position).map(report => {
          return parseFloat(report.total_expense || '0')
        }).reduce((a, b) => a + b, 0);
      } else {
        value = this.filteredReports.map(report => {
          return parseFloat(report.total_expense || '0')
        }).reduce((a, b) => a + b, 0);
      }

      return value;
    },

    formatTotalMarginByPosition(position) {
      let value

      if (position) {
        value = this.filteredReports.filter(report => report.position === position).map(report => {
          return parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')
        }).reduce((a, b) => a + b, 0);
      } else {
        value = this.filteredReports.map(report => {
          return parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')
        }).reduce((a, b) => a + b, 0);
      }

      return value
    },

    totalQuantityByPosition(position) {
      let containerTotal = 0
      let wagonTotal = 0

      this.filteredReports.forEach(report => {

        if (position) {
          if (report.position !== position) {
            return
          }
        }

        if (report.child_type === 'container_order') {
          const containers = report.container_types || []
          containers.forEach(container => {
            containerTotal += container.quantity || 0
          })
        } else {
          wagonTotal += report.quantity || 0
        }
      })

      return [containerTotal, wagonTotal]
    },

    sharesByPosition(position) {
      const total_agreed_rate = this.filteredReports.map(report => {
        return parseFloat(report.total_agreed_rate || '0')
      }).reduce((a, b) => a + b, 0);


      const total_agreed_rate_by_position = this.filteredReports.filter(report => report.position === position).map(report => {
        return parseFloat(report.total_agreed_rate || '0')
      }).reduce((a, b) => a + b, 0);


      return ((total_agreed_rate_by_position / (total_agreed_rate || 1)) * 100).toFixed(2)

    },

    async exportToExcel() {
      const workbook = new ExcelJS.Workbook();

      // Add Summary worksheet
      const summarySheet = workbook.addWorksheet('Summary');

      // Summary sheet columns
      summarySheet.columns = [
        {header: 'Category', key: 'category', width: 25},
        {header: 'Total', key: 'total', width: 20},
        {header: 'Block Train', key: 'block_train', width: 20},
        {header: 'Multi modal', key: 'multi_modal', width: 20},
        {header: 'Rail Forwarder', key: 'rail_forwarder', width: 20}
      ];

      let managers = []

      if (this.form.manager_ids.length > 0) {
        this.form.manager_ids.forEach(manager_id => {
          try {
            let manager = this.multiselect.options.find(m => m.value === manager_id)
            managers.push(manager.label)
          } catch {
            console.log('error')
          }
        })
      } else {
        managers = ['All']
      }

      // Add date range rows at the top
      summarySheet.insertRow(1, ['Platform:', this.form.platform.toUpperCase()])
      summarySheet.insertRow(2, ['Managers:', managers.join(', ')])
      summarySheet.insertRow(3, ['Date From:', this.form.date_from])
      summarySheet.insertRow(4, ['Date To:', this.form.date_to])
      summarySheet.insertRow(5, ['Date Generated:', moment(new Date()).format('YYYY-MM-DD hh:mm a')])
      summarySheet.insertRow(6, ['Generated by:', this.getUserUsername])

      summarySheet.insertRow(7, []);

      const headerRow = summarySheet.getRow(8);
      headerRow.values = summarySheet.columns.map(col => col.header);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFD3D3D3'} // Light grey background color
        };
        cell.font = {bold: true};
      });
      headerRow.commit();

      // Add rows to summary sheet
      const summaryData = [
        {
          category: 'Total agreed rates',
          total: this.totalRevenue(),
          block_train: this.formatTotalAgreedRateByPosition('block_train'),
          multi_modal: this.formatTotalAgreedRateByPosition('multi_modal'),
          rail_forwarder: this.formatTotalAgreedRateByPosition('rail_forwarder'),
        },
        {
          category: 'Total Cost of Sales',
          total: this.formatTotalExpenseByPosition(),
          block_train: this.formatTotalExpenseByPosition('block_train'),
          multi_modal: this.formatTotalExpenseByPosition('multi_modal'),
          rail_forwarder: this.formatTotalExpenseByPosition('rail_forwarder'),
        },
      ];

      if (this.show_margin) {
        summaryData.push({
          category: 'Total margin',
          total: this.formatTotalMarginByPosition(),
          block_train: this.formatTotalMarginByPosition('block_train'),
          multi_modal: this.formatTotalMarginByPosition('multi_modal'),
          rail_forwarder: this.formatTotalMarginByPosition('rail_forwarder'),
        })
      }

      summaryData.push({
        category: 'Share of Revenue',
        total: '100%',
        block_train: this.sharesByPosition('block_train') + '%',
        multi_modal: this.sharesByPosition('multi_modal') + '%',
        rail_forwarder: this.sharesByPosition('rail_forwarder') + '%',
      })

      // Add quantity data
      const totalQuantities = this.totalQuantityByPosition();
      const blockTrainQuantities = this.totalQuantityByPosition('block_train');
      const multiModalQuantities = this.totalQuantityByPosition('multi_modal');
      const railForwarderQuantities = this.totalQuantityByPosition('rail_forwarder');

      summaryData.push(
          {
            category: 'Total containers',
            total: parseInt(totalQuantities[0]),
            block_train: parseInt(blockTrainQuantities[0]),
            multi_modal: parseInt(multiModalQuantities[0]),
            rail_forwarder: parseInt(railForwarderQuantities[0])
          },
          {
            category: 'Total wagons',
            total: parseInt(totalQuantities[1]),
            block_train: parseInt(blockTrainQuantities[1]),
            multi_modal: parseInt(multiModalQuantities[1]),
            rail_forwarder: parseInt(railForwarderQuantities[1])
          }
      );

      const targetRevenueByPosition = (position_label) => {
        try {
          const result = this.$refs.SalesReportWidgets.positions.find(i => i.label === position_label)
          return (result.target).toFixed(2)
        } catch {
          return 0
        }
      }

      const targetRevenueDifferenceByPosition = (position_label) => {
        try {
          const result = this.$refs.SalesReportWidgets.positions.find(i => i.label === position_label)
          return (result.total_revenue - result.target).toFixed(2)
        } catch {
          return 0
        }
      }

      const targetRevenueDifferencePercentageByPosition = (position_label) => {
        try {
          const result = this.$refs.SalesReportWidgets.positions.find(i => i.label === position_label)
          return ((this.$refs.SalesReportWidgets.targetRevenueDifference(result) / result.total_revenue) * 100).toFixed(2)
        } catch {
          return 0
        }
      }

      if (this.isDateRangeInMonth.ok) {
        summaryData.push({})
        summaryData.push({
          category: `${this.isDateRangeInMonth.month} Target Revenue`,
          total: '',
          block_train: targetRevenueByPosition('Block Train'),
          multi_modal: targetRevenueByPosition('Multi modal'),
          rail_forwarder: targetRevenueByPosition('Rail Forwarder'),
        })
        summaryData.push({
          category: 'Difference +-',
          total: '',
          block_train: targetRevenueDifferenceByPosition('Block Train'),
          multi_modal: targetRevenueDifferenceByPosition('Multi modal'),
          rail_forwarder: targetRevenueDifferenceByPosition('Rail Forwarder'),
        })
        summaryData.push({
          category: '%',
          total: '',
          block_train: `${targetRevenueDifferencePercentageByPosition('Block Train')} %`,
          multi_modal: `${targetRevenueDifferencePercentageByPosition('Multi modal')} %`,
          rail_forwarder: `${targetRevenueDifferencePercentageByPosition('Rail Forwarder')} %`
        })
      }

      // Add and format summary rows
      summaryData.forEach(data => {
        const row = summarySheet.addRow(data);
        row.getCell('category').font = {bold: true};

        // Format currency cells if the row is for amounts
        if (['Total agreed rates', 'Total expenses', 'Total margin'].includes(data.category)) {
          ['total', 'block_train', 'multi_modal', 'rail_forwarder'].forEach(col => {
            const cell = row.getCell(col);
            if (data.category === 'Total margin') {
              const value = parseFloat((cell.value || '').toString().replace(/[^0-9.-]+/g, ''));
              cell.font = {
                color: {argb: value >= 0 ? 'FF098215' : 'FFFF0000'}
              };
            }
          });
        }
      });

      // Add Details worksheet
      const detailsSheet = workbook.addWorksheet('Sales Report', {
        views: [{state: 'frozen', ySplit: 1}]
      });

      detailsSheet.columns = [
        {header: '#', key: 'index', width: 5},
        {header: 'Manager', key: 'manager', width: 25},
        {header: 'Customer', key: 'customer', width: 35},
        {header: 'Route', key: 'route', width: 35},
        {header: 'Cargo', key: 'cargo', width: 35},
        {header: 'Position', key: 'position', width: 20},
        {header: 'Order Number', key: 'order_number', width: 20},
        {header: 'Date Created', key: 'date', width: 15},
        {header: 'Volume', key: 'volume', width: 20},
        {header: 'Sales Sum', key: 'sales_sum', width: 20}
      ].concat(this.show_margin ? [{header: 'Margin', key: 'margin', width: 20}] : []);

      const salesHeaderRow = detailsSheet.getRow(1);
      salesHeaderRow.values = detailsSheet.columns.map(col => col.header);

      salesHeaderRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFD3D3D3'}
        };
        cell.font = {bold: true};
      });

      salesHeaderRow.commit();

      let totalSalesSum = 0;
      let totalVolume = 0;
      let totalMargin = 0;

      this.filteredReports.forEach((report, index) => {
        const managerName = report.user
            ? `${report.user.first_name || ''} ${report.user.last_name || ''}`.trim()
            : '';

        const route = report.departure && report.destination
            ? `${report.departure.name} (${report.departure.code}) - ${report.destination.name} (${report.destination.code})`
            : '';

        const cargo = report.product
            ? `${report.product.name} (${report.product.hc_code})`
            : '';

        let volumeValue = 0;
        let volumeDisplay = '';

        if (report.child_type === 'container_order') {
          volumeValue = report.container_types.reduce((sum, type) => sum + type.quantity, 0);
          volumeDisplay = report.container_types.map(type => `${type.quantity}x${type.type}`).join(', ');
        } else {
          volumeValue = report.quantity || 0;
          volumeDisplay = `${volumeValue}xW`;
        }

        const salesSumValue = parseFloat(report.total_agreed_rate || '0');
        const marginValue = salesSumValue - parseFloat(report.total_expense || '0');

        totalSalesSum += salesSumValue;
        totalVolume += volumeValue;
        if (this.show_margin) totalMargin += marginValue;

        const rowData = {
          index: index + 1,
          manager: managerName,
          customer: report.company?.name || '',
          route: route,
          cargo: cargo,
          position: this.object_keys[report.position],
          order_number: report.order_number,
          date: report.date,
          volume: volumeDisplay,
          sales_sum: salesSumValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        };

        if (this.show_margin) {
          rowData.margin = marginValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        }

        const row = detailsSheet.addRow(rowData);

        if (this.show_margin) {
          const marginCell = row.getCell('margin');
          marginCell.font = {
            color: {argb: marginValue >= 0 ? 'FF098215' : 'FFFF0000'}
          };
        }
      });

// Add total row to details sheet
      const totalRowData = {
        index: 'Total',
        volume: totalVolume.toString(),
        sales_sum: totalSalesSum.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      };

      if (this.show_margin) {
        totalRowData.margin = totalMargin.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }

      const detailsTotalRow = detailsSheet.addRow(totalRowData);

      detailsTotalRow.getCell('volume').font = {bold: true};
      detailsTotalRow.getCell('sales_sum').font = {bold: true};

      if (this.show_margin) {
        const totalMarginCell = detailsTotalRow.getCell('margin');
        totalMarginCell.font = {
          color: {argb: totalMargin >= 0 ? 'FF098215' : 'FFFF0000'},
          bold: true
        };
      }

      const mergeEndCol = 6; // Merge up to Position column
      detailsSheet.mergeCells(detailsTotalRow.number, 1, detailsTotalRow.number, mergeEndCol);
      detailsTotalRow.getCell('A').value = 'Total';
      detailsTotalRow.getCell('A').font = {bold: true};

      detailsSheet.autoFilter = {
        from: {row: 1, column: 1},
        to: {row: 1, column: detailsSheet.columns.length}
      };

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
          new Blob([buffer]),
          `${this.form.platform.toUpperCase()}_Sales_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`
      );
    },

    syncDateRangeOptionsWithCurrentYear() {
      this.date_range_options = [
        {
          value: 'last_week',
          label: 'Last Week',
          date: new moment().subtract(6, 'days').format('ll') + ' - ' + new moment().format('ll'),
        },
        {
          value: 'last_month',
          label: 'Last Month',
          date: new moment().subtract(30, 'days').format('ll') + ' - ' + new moment().format('ll'),
        },
        {
          value: 'current_year',
          label: 'Current Year',
          date: new moment(`${this.current_year}-01-01`).startOf('year').format('ll') + ' - ' + new moment(`${this.current_year}-01-01`).endOf('year').format('ll'),
        },
        // {
        //   value: 'custom',
        //   label: 'Custom',
        //   date: new moment().format('ll - ll'),
        // },
      ]

      if (this.current_year === new moment().year()) {
        this.date_range_options.unshift({
          value: 'today',
          label: 'Today',
          date: new moment().format('MMM Do'),
        })
        this.form.date_range_option = 'today'
      } else {
        this.form.date_range_option = 'last_week'
        this.date_range_options = this.date_range_options.filter(i => i.value !== 'today')
      }

      this.date_range_months = [];

      const currentYear = new moment().year(); // Get the actual current year
      const selectedYear = new moment(`${this.current_year}-01-01`).year(); // Year you want to process
      const currentMonth = new moment().month(); // Current month (0-indexed)

      const monthsToProcess = selectedYear === currentYear ? currentMonth : 11; // Up to the current month for the current year, otherwise all months

      for (let month = 0; month <= monthsToProcess; month++) {
        const startOfMonth = new moment().year(selectedYear).month(month).startOf('month');
        const endOfMonth = new moment().year(selectedYear).month(month).endOf('month');
        this.date_range_months.push({
          value: `month_${month + 1}`,
          label: new moment().month(month).format('MMMM'),
          date: `${startOfMonth.format('DD/MM')} - ${endOfMonth.format('DD/MM')}`,
          date_from: startOfMonth.format('YYYY-MM-DD'),
          date_to: endOfMonth.format('YYYY-MM-DD'),
        });
      }

      this.setDateRange(this.form.date_range_option);
    }
  },
  mounted() {
    if (this.$route.query.year) {
      try {
        this.current_year = parseInt(this.$route.query.year, 10);
      } catch {
        this.current_year = this.$route.query.year;
      }
    }

    this.syncDateRangeOptionsWithCurrentYear()
    this.setDateRange(this.form.date_range_option)
    this.asyncFindUsers()
  },
  watch: {
    '$route.query.year'(newValue) {
      try {
        this.current_year = parseInt(newValue, 10);
      } catch {
        this.current_year = newValue
      }
      this.syncDateRangeOptionsWithCurrentYear()
    }
  }
};
</script>

<template>

  <b-modal v-model="showModal" hide-footer centered fullscreen>

    <template v-slot:title>
      Sales Report - <b class="text-uppercase">{{ form.platform }}</b>
    </template>

    <div class="d-flex align-items-center flex-wrap gap-3 mb-3">
      <div>
        <Multiselect
            v-bind="multiselect"
            v-model="form.manager_ids"
            class="shadow-none" style="min-width: 250px;"
            :closeOnSelect="false"
        />
      </div>
      <div>
        <div class="input-group w-100">
          <label class="input-group-text">
            Date From
          </label>
          <input v-model="form.date_from" type="date" class="form-control" placeholder="0"/>
        </div>
      </div>
      <div>
        <div class="input-group w-100">
          <label class="input-group-text">Date To</label>
          <input v-model="form.date_to" type="date" class="form-control" placeholder="0"/>
        </div>
      </div>
      <div>
        <b-button @click="generateReport(false)"
                  variant="soft-primary"
                  class="w-100"
                  :disabled="reports_request_status === 'loading'"
        >
          {{ reports_request_status === 'loading' ? 'Generating Report...' : 'Generate Report' }}
        </b-button>
      </div>
      <div>
        <b-button @click="exportToExcel()"
                  variant="soft-success"
                  class="w-100"
        >
          Export to Excel
        </b-button>
      </div>
      <div>
        <div class="form-check">
          <input v-model="show_margin" class="form-check-input" type="checkbox" id="displayMargin"
                 :checked="show_margin">
          <label class="form-check-label" for="displayMargin">
            Display margin
          </label>
        </div>
      </div>
    </div>

    <div v-if="reports_request_status === 'error'" class="mb-3">
      <p class="mb-0 fw-medium text-danger">
        Unexpected Error. Couldn't load sales report (
      </p>
    </div>

    <div v-if="reports_request_status === 'success'">

      <SalesReportWidgets
          ref="SalesReportWidgets"
          :total_revenue="totalRevenue()"
          :total_cost_of_sales="formatTotalExpenseByPosition()"
          :total_margin="formatTotalMarginByPosition()"
          :share_of_revenue="100"
          :quantitiesList="totalQuantityByPosition()"
          :positions-list="[
              {
                label: 'Block Train',
                total_revenue: formatTotalAgreedRateByPosition('block_train'),
                total_cost_of_sales: formatTotalExpenseByPosition('block_train'),
                total_margin: formatTotalMarginByPosition('block_train'),
                share_of_revenue: sharesByPosition('block_train'),
                quantitiesList: totalQuantityByPosition('block_train'),
              },
              {
                label: 'Multi modal',
                total_revenue: formatTotalAgreedRateByPosition('multi_modal'),
                total_cost_of_sales: formatTotalExpenseByPosition('multi_modal'),
                total_margin: formatTotalMarginByPosition('multi_modal'),
                share_of_revenue: sharesByPosition('multi_modal'),
                quantitiesList: totalQuantityByPosition('multi_modal'),
              },
               {
                label: 'Rail Forwarder',
                total_revenue: formatTotalAgreedRateByPosition('rail_forwarder'),
                total_cost_of_sales: formatTotalExpenseByPosition('rail_forwarder'),
                total_margin: formatTotalMarginByPosition('rail_forwarder'),
                share_of_revenue: sharesByPosition('rail_forwarder'),
                quantitiesList: totalQuantityByPosition('rail_forwarder'),
              },
          ]"
          :display_margin="show_margin"
          :date_from="form.date_from"
          :date_to="form.date_to"
      />

      <div class="table-responsive">
        <table class="table table-bordered table-nowrap">
          <thead>
          <tr class="border-0 align-middle">
            <th class="border-0" colspan="9">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <template v-for="month in date_range_months" :key="`month_${month.value}`">
                  <h5 @click="setFormDateRanges(month, false)" class="mb-0 cursor-pointer">
                  <span :class="{
                    'badge-gradient-info text-light': month.date_from === form.date_from && month.date_to === form.date_to,
                    'badge-soft-primary': 2,
                  }"
                        class="badge">{{ month.label }}</span>
                  </h5>
                </template>
              </div>
            </th>
            <th class="text-center border bg-light">
              x{{ total_quantity }}
            </th>
            <th class="text-center border bg-light">
              $ {{
                filteredReports.map(report => parseFloat(report.total_agreed_rate || '0')).reduce((a, b) => a + b, 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </th>
            <th v-if="show_margin" class="text-center border bg-light" :class="{
            'text-success': filteredReports.map(report => {
                return parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')
              }).reduce((a, b) => a + b, 0) >= 0,
              'text-danger': filteredReports.map(report => {
                return parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')
              }).reduce((a, b) => a + b, 0) < 0,
          }">
              $ {{
                filteredReports.map(report => {
                  return parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')
                }).reduce((a, b) => a + b, 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }}
            </th>
          </tr>
          <tr class="align-middle">
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center"></th>
            <th scope="col">Manager</th>
            <th style="max-width: 350px" scope="col">Customer</th>
            <th style="max-width: 350px" scope="col">Route</th>
            <th style="max-width: 350px" scope="col">Cargo</th>
            <th class="text-center" scope="col">Position</th>
            <th class="text-center" scope="col">Order Number</th>
            <th class="text-center" scope="col">Date Created</th>
            <th style="max-width: 350px" class="text-center" scope="col">Volume</th>
            <th class="text-center" scope="col">Sales Sum</th>
            <th v-if="show_margin" class="text-center" scope="col">Margin</th>
          </tr>
          <tr>
            <th></th>
            <th class="p-1">
              <select class="form-control" v-model="search.platform">
                <option value="" selected disabled>Select platform</option>
                <option value="">Both</option>
                <option value="ag">System</option>
                <option value="ca">Central Asia</option>
              </select>
            </th>
            <th class="p-1">
              <input class="form-control border-0" v-model="search.manager" placeholder="Search for managers...">
            </th>
            <th class="p-1">
              <input class="form-control border-0" v-model="search.customer" placeholder="Search for customers...">
            </th>
            <th class="p-1">
              <input class="form-control border-0" v-model="search.route" placeholder="Search for routes...">
            </th>
            <th class="p-1">
              <input class="form-control border-0" v-model="search.cargo" placeholder="Search for cargos...">
            </th>
            <th class="p-1">
              <input class="form-control border-0 text-center" v-model="search.position"
                     placeholder="Search for positions...">
            </th>
            <th class="p-1">
              <input class="form-control border-0 text-center" v-model="search.order_number"
                     placeholder="Search for order number...">
            </th>
            <th class="p-1">
              <input class="form-control border-0 text-center" v-model="search.date"
                     placeholder="Search for date created...">
            </th>
            <th style="max-width: 350px" class="p-1">
              <input class="form-control border-0 text-center" v-model="search.volume"
                     placeholder="Search for volumes...">
            </th>
            <th class="p-1">
              <input class="form-control border-0 text-center" v-model="search.sales_sum"
                     placeholder="Search for sales sum...">
            </th>
            <th v-if="show_margin" class="p-1">
              <input class="form-control border-0 text-center" v-model="search.margin"
                     placeholder="Search for margin...">
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(report, index) in filteredReports" :key="`report_${report}`">
            <tr class="align-middle">
              <th class="text-center" scope="row">{{ index + 1 }}</th>
              <th
                  v-b-tooltip.hover :title="`Platform: ${report.platform === 'ag' ? 'System' : 'Central Asia'}`"
                  class="text-center text-uppercase" scope="row">{{ report.platform }}
              </th>
              <td>
                <div class="avatar-group" id="newMembar">
                  <a class="avatar-group-item d-flex align-items-center gap-2">
                    <div class="avatar-xs">
                      <div class="avatar-title rounded-circle material-shadow bg-soft-primary text-primary">
                        {{
                          report.user
                              ? report.user.first_name === '' ? (report.user.username || ' ')[0].toUpperCase() : (report.user.first_name || ' ')[0].toUpperCase() + '' + (report.user.last_name || ' ')[0].toUpperCase()
                              : '-'
                        }}
                      </div>
                    </div>
                    <span>
                  {{
                        report.user
                            ? report.user.first_name === '' ? report.user.username : report.user.first_name + ' ' + report.user.last_name
                            : '-'
                      }}
                </span>
                  </a>
                </div>
              </td>
              <td style="max-width: 350px" class="text-truncate">
                {{ report.company ? report.company.name : '-' }}
              </td>
              <td style="max-width: 350px">
                <div class="d-flex align-items-center gap-1 text-truncate">
                  <i class="bx bx-map text-success"></i>
                  {{
                    report.departure
                        ? report.departure.name + ' (' + report.departure.code + ')'
                        : ''
                  }}
                  -
                  <i class="bx bx-map text-danger"></i>
                  {{
                    report.destination
                        ? report.destination.name + ' (' + report.destination.code + ')'
                        : ''
                  }}
                </div>
              </td>
              <td style="max-width: 350px" class="text-truncate">
                {{
                  report.product
                      ? report.product.name + ' (' + report.product.hc_code + ')'
                      : ''
                }}
              </td>
              <td class="text-center fw-medium">
                {{ object_keys[report.position] }}
              </td>
              <td class="text-center">
                <a :href="`${report.platform === 'ag' ? '' : 'https://ca.interrail.uz'}${object_keys[report.child_type]}/${report.order_number}/`"
                   target="_blank">
                  <span class="badge badge-soft-primary fs-12">
                    {{ report.order_number }}
                  </span>
                </a>
              </td>
              <td class="text-center">
                {{ report.date || '-' }}
              </td>
              <td style="max-width: 350px" class="text-center">
                <div v-if="report.child_type === 'container_order'"
                     class="d-flex gap-2 flex-wrap align-items-center justify-content-center">
                  <template v-for="type in report.container_types"
                            :key="`container_type_${type.type}_${report.order_number}`">
                    <h6 class="mb-0 fs-15">
                    <span class="badge badge-soft-primary">
                      {{ type.quantity }}x{{ type.type }}
                    </span>
                    </h6>
                  </template>
                </div>
                <h6 v-else class="mb-0 fs-15">
                    <span class="badge badge-soft-primary">
                     {{ report.quantity || 0 }}xW
                    </span>
                </h6>
              </td>
              <td class="text-center">
                $ {{
                  parseFloat(report.total_agreed_rate || '0').toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                }}
              </td>
              <td v-if="show_margin" class="text-center" :class="{
            'text-success': parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0') >= 0,
            'text-danger': parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0') < 0,
          }">
                $ {{
                  (parseFloat(report.total_agreed_rate || '0') - parseFloat(report.total_expense || '0')).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else-if="reports_request_status === 'loading'" class="py-5 d-flex gap-2 align-items-center">
      <i class="mdi mdi-loading mdi-spin fs-2"></i> Loading...
    </div>

    <div v-if="reports_request_status !== 'loading'" class="text-center mt-4">
      <b-button @click="showModal = false" variant="soft-primary">
        Ok
      </b-button>
    </div>

  </b-modal>

  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-0 d-flex align-items-center">
        <span class="badge badge-gradient-info me-2">{{ current_year }}</span>
        Sales Report
      </b-card-title>
    </b-card-header>
    <b-card-body class="p-0">
      <div class="p-3">

        <b-row class="gy-3">

          <b-col cols="12">
            <label class="form-label">
              Manager
              <small class="text-muted">(optional)</small>
            </label>
            <Multiselect
                v-bind="multiselect"
                v-model="form.manager_ids"
                class="shadow-none"
                :closeOnSelect="false"
            />
          </b-col>

          <b-col cols="12">
            <div class="dropdown">
              <div data-bs-toggle="dropdown"
                   aria-haspopup="true" type="button"
                   aria-expanded="false"
                   class="d-flex justify-content-between align-items-center gap-3 border rounded-3 p-2 px-3">
                <span>
                  {{
                    form.date_range_option.split('_').map(i => i.charAt(0).toUpperCase() + i.slice(1, i.length)).join(' ')
                  }}
                </span>
                <span>
                  {{
                    date_range_options.filter(i => i.value === form.date_range_option).length
                        ? date_range_options.find(i => i.value === form.date_range_option).date
                        : ''
                  }}
                </span>
              </div>
              <div class="dropdown-menu dropdown-menu-start">
                <template v-for="option in date_range_options" :key="`option_${option}`">
                  <a @click="setDateRange(option.value)"
                     class="dropdown-item d-flex justify-content-between align-items-center gap-3"
                     :class="{'active': form.date_range_option === option.value}" style="cursor: pointer">
                    {{ option.label }}
                    <small class="text-muted ms-3">
                      {{ option.value !== 'custom' ? option.date : '-' }}
                    </small>
                  </a>
                </template>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="d-flex align-items-center gap-3">
              <label class="form-label mb-0">Platform:</label>
              <div class="d-flex align-items-center gap-3">
                <div class="form-check">
                  <input v-model="form.platform" class="form-check-input" type="radio" id="platformBOTH" value="both">
                  <label class="form-check-label" for="platformBOTH">
                    Both
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="form.platform" class="form-check-input" type="radio" id="platformAG" value="ag">
                  <label class="form-check-label" for="platformAG">
                    AG
                  </label>
                </div>
                <div class="form-check">
                  <input v-model="form.platform" class="form-check-input" type="radio" id="platformCA" value="ca">
                  <label class="form-check-label" for="platformCA">
                    CA
                  </label>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="form-check">
              <input v-model="show_margin" class="form-check-input" type="checkbox" id="displayMargin"
                     :checked="show_margin">
              <label class="form-check-label" for="displayMargin">
                Display margin
              </label>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="d-flex align-items-center gap-3 flex-wrap">
              <template v-for="month in date_range_months" :key="`month_${month.value}`">
                <h5 @click="setFormDateRanges(month, true)" class="mb-0 cursor-pointer">
                    <span
                        v-b-tooltip.hover :title="`${month.date_from} / ${month.date_to}`"
                        class="badge" :class="{
                    'badge-gradient-info text-light': month.date_from === form.date_from && month.date_to === form.date_to,
                    'badge-soft-primary': 2,
                  }">{{ month.label }}</span>
                </h5>
              </template>
            </div>
          </b-col>

          <template v-if="form.date_range_option === 'custom'">
            <b-col cols="12">
              <div class="input-group w-100">
                <label class="input-group-text">
                  Date From
                </label>
                <input v-model="form.date_from" type="date" class="form-control" placeholder="0"/>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="input-group w-100">
                <label class="input-group-text">Date To</label>
                <input v-model="form.date_to" type="date" class="form-control" placeholder="0"/>
              </div>
            </b-col>
          </template>

          <b-col cols="12" v-if="reports_request_status === 'success' && reports.length === 0">
            <p class="mb-0 fw-medium text-danger">
              No orders available for selected date range or manager
            </p>
          </b-col>
          <b-col cols="12" v-if="reports_request_status === 'error'">
            <p class="mb-0 fw-medium text-danger">
              Unexpected Error. Couldn't load sales report (
            </p>
          </b-col>
          <b-col cols="12">
            <b-button @click="generateReport()"
                      variant="soft-primary"
                      class="w-100"
                      :disabled="reports_request_status === 'loading'"
            >
              {{ reports_request_status === 'loading' ? 'Generating Report...' : 'Generate Report' }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card>
</template>
